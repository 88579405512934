import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import axios from 'axios'
import store from './shared/store/store'

//Importar Prime Vue
import PrimeVue from 'primevue/config';
import 'primevue/resources/themes/aura-light-blue/theme.css'
import 'primeicons/primeicons.css'

//Components Prime Vue
import FloatLabel from 'primevue/floatlabel';
import InputText from 'primevue/inputtext';
import Password from 'primevue/password';
import Button from 'primevue/button';
import Menu from 'primevue/menu';
import Sidebar from 'primevue/sidebar';
import IconField from 'primevue/iconfield';
import InputIcon from 'primevue/inputicon';
import Dropdown from 'primevue/dropdown';
import Card from 'primevue/card';
import SplitButton from 'primevue/splitbutton';
import Toast from 'primevue/toast';
import ToastService from 'primevue/toastservice';
import Avatar from 'primevue/avatar';
import AvatarGroup from 'primevue/avatargroup'; 
import Badge from 'primevue/badge';
import BadgeDirective from 'primevue/badgedirective';
import Dialog from 'primevue/dialog';
import MultiSelect from 'primevue/multiselect';
import ProgressBar from 'primevue/progressbar';
import RadioButton from 'primevue/radiobutton';
import Textarea from 'primevue/textarea';
import InputSwitch from 'primevue/inputswitch';
import FileUpload from 'primevue/fileupload';
import Editor from 'primevue/editor';
import Chart from 'primevue/chart';
import Tooltip from 'primevue/tooltip';
import ProgressSpinner from 'primevue/progressspinner';
import { Quill } from 'quill'

const app = createApp(App)

//produccion
axios.defaults.baseURL= 'https://proyecta.cascadastamasopo.com/api/'

//Dev
//axios.defaults.baseURL = 'http://192.168.1.123:3000/api/'

app.use(PrimeVue);
app.use(Quill);

app.component('Button', Button);
app.component('Password', Password);
app.component('InputText', InputText);
app.component('FloatLabel', FloatLabel);
app.component('Menu', Menu);
app.component('Sidebar', Sidebar);
app.component('IconField', IconField);
app.component('InputIcon', InputIcon);
app.component('Dropdown', Dropdown);
app.component('Card', Card);
app.component('SplitButton', SplitButton);
app.component('Toast', Toast);
app.component('Avatar', Avatar);
app.component('AvatarGroup', AvatarGroup);
app.component('Dialog', Dialog);
app.component('Badge', Badge);
app.component('MultiSelect', MultiSelect);
app.component('ProgressBar', ProgressBar);
app.component('RadioButton', RadioButton);
app.component('Textarea', Textarea);
app.component('InputSwitch', InputSwitch);
app.component('Editor', Editor);
app.component('FileUpload', FileUpload);
app.component('Chart', Chart);
app.component('ProgressSpinner', ProgressSpinner);

app.directive('badge', BadgeDirective);
app.directive('tooltip', Tooltip);

app.use(store); 
app.use(router);
app.use(ToastService);
//app.use(Editor);

app.mount('#app')

//createApp(App).use(router).mount('#app')