import { createStore } from 'vuex';


const store = createStore({
  state: {
    user: null 
  },
  mutations: {
    setUser(state, newUser) {
      state.user = newUser;
    }
  },
  actions: {
    saveUser({ commit }, newUser) {
      commit('setUser', newUser);
    }
  },
  getters: {
    getUser(state) {
      return state.user;
    }
  }
})

export default store