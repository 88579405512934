import { createRouter, createWebHashHistory } from 'vue-router'
import isAuthenticatedGuard from './auth-guard'
import autoLoginGuard from './auth-guard-login'

const routes = [
  {
    path: '/',
    beforeEnter:[ isAuthenticatedGuard ],
    component: () => import( /* webpackChunkName: "HomeLayout"*/ '@/modules/home/layouts/HomeLayout.vue'),
    children:[
      {
        path: 'dashboard',
        name:'dashboard',
        component: () => import( /* webpackChunkName: "DashboardPage"*/ '@/modules/home/pages/DashboardPage.vue')
      },
      {
        path: 'messages',
        name:'messages',
        component: () => import( /* webpackChunkName: "MessagesPage"*/ '@/modules/home/pages/MessagesPage.vue')
      },
      {
        path: 'projects',
        name:'projects',
        component: () => import( /* webpackChunkName: "ProjectsPage"*/ '@/modules/home/pages/ProjectsPage.vue')
      },
      {
        path: 'teams',
        name:'teams',
        component: () => import( /* webpackChunkName: "TeamsPage"*/ '@/modules/home/pages/TeamsPage.vue')
      },
      {
        path: 'users',
        name:'users',
        component: () => import( /* webpackChunkName: "UsersPage"*/ '@/modules/home/pages/UsersPage.vue')
      },
      {
        path: 'users',
        name:'users',
        component: () => import( /* webpackChunkName: "UsersPage"*/ '@/modules/home/pages/UsersPage.vue')
      },

      {
        path: '/guide',
        name:'guide', 
        component: () => import( /* webpackChunkName: "GuidePage"*/ '@/modules/guide/Guide.vue'),
      },

      { 
        path: '', 
        redirect: '/dashboard'
      },
    ]
  },
  {
    path: '/login',
    name:'auth',
    beforeEnter:[ autoLoginGuard ],
    component: () => import( /* webpackChunkName: "AuthLayout"*/ '@/modules/auth/layouts/AuthLayout.vue'),
    children: [
      {
        path: '',
        name:'login',
        component: () => import( /* webpackChunkName: "LoginPage"*/ '@/modules/auth/pages/LoginPage.vue')
      },
      {
        path: 'forgot',
        name:'forgot',
        component: () => import( /* webpackChunkName: "ForgotPage"*/ '@/modules/auth/pages/ForgotPage.vue')
      },
      {
        path: 'reset/:id',
        name:'reset-pass',
        component: () => import( /* webpackChunkName: "ResetPage"*/ '@/modules/auth/pages/ResetPage.vue'),
        props: ( router ) =>{
          const { id } = router.params
          return { id }
        }
      },   
    ]
  },


//  {
  //  path: '/guide',
  //  name:'guide',
  //  beforeEnter:[ isAuthenticatedGuard ],
  //  component: () => import( /* webpackChunkName: "GuidePage"*/ '@/modules/guide/Guide.vue'),
//  },


  { 
    path: '/:pathMatch(.*)*', 
    redirect: '/'
  },
]

const router = createRouter({
  history: createWebHashHistory(),
  routes
})

export default router