import axios from 'axios';
import { useStore } from 'vuex'; 

const isAuthenticatedGuard =  async( to, from, next ) =>{
    const store = useStore(); 

    return new Promise((resolve, reject) => {
        let header = {'token': localStorage.getItem('token') }
        let config = {headers:header}
        axios.get('auth/token',config)
        .then((response) => {
            next()
            const user = response.data;
            store.dispatch('saveUser',user);
        })
        .catch((error) => {
            next({name: 'login'})
        });    
    })
}
export default isAuthenticatedGuard