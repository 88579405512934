<template>
  <router-view/>
</template>

<script>
export default{ name: 'App' }
</script>

<style>
  
  #app {
    font-family: Onest;
    color: #12192C;
    background-color: #F8F8F8;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
  }

  .lbl-form{
    color: #585E6C;
    font-size: 16px;
    width: 100%;
  }

  .display-main{
    font-weight: bold;
    font-size: 25px;
  }

  h1{
    font-weight: bold;
    font-size: 22px;
  }

  h2{
    font-weight: bold;
    font-size: 18px;
  }

  h3{
    font-weight: bold;
    font-size: 16px;
  }
  
  .toast-styles{ max-width: 90%; }

  .p-multiselect-panel .p-multiselect-header .p-multiselect-close {
    display: none;
  }

</style>
